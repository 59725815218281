export const SERVICES = [
	{
		title: 'Software Development',
		description:
			'Custom software development services tailored to various industries and business needs. Our team of experts provides full-cycle development, from concept and design to testing and support.',
		list: [
			{
				header: 'Application Architecture Design',
				text: 'Lay the groundwork for your application’s success by meticulously designing its architecture. Our team ensures scalability, performance, and future-proofing are at the core, providing a sturdy foundation for your project’s growth and evolution.',
			},
			{
				header: 'Application Development',
				text: 'Bring your vision to life with our expert development team, leveraging cutting-edge technologies to build robust and user-friendly applications.',
			},
			{
				header: 'Testing',
				text: 'Rigorously test every aspect of your application to ensure functionality, security, and seamless user experience across various devices and platforms.',
			},
			{
				header: 'Setting up CI/CD and Application Deployment',
				text: 'Implement continuous integration and continuous deployment (CI/CD) pipelines to automate the build, testing, and deployment processes, enabling faster and more reliable releases of your application.',
			},
		],
		image: 'software-dev',
		link: 'service-development',
	},
	{
		title: 'Mobile App Development',
		description:
			'Creation of iOS and Android mobile applications that deliver high performance, user-friendliness, and attractive design. Our team of developers ensures full support from idea to app store release.',
		list: [
			{
				header:
					'Creating Cross-Platform Application Architecture for Selected OS',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Application Development',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Testing',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'CI/CD Setup and Application Deployment',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
		],
		image: 'mobile-app',
		link: 'mobile-app-development',
	},
	{
		title: 'Machine Learning & Artificial Intelligence integration',
		shortTitle: 'ML & AI integration',
		description:
			'Integration of machine learning and artificial intelligence technologies into software and business processes. We help organizations leverage data for task automation, trend forecasting, and decision-making enhancement.',
		list: [
			{
				header: 'Creating Solution Architecture',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Solution Implementation',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Testing',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Setting up CI/CD and Application Deployment',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
		],
		image: 'machine-learning',
		link: 'MA-AII',
	},
	{
		title: 'UX & UI Design',
		description:
			'User experience (UX) and user interface (UI) design for websites, mobile apps, and other digital products. Our design team aims to create aesthetically pleasing and intuitive interfaces, considering user needs and business goals.',
		list: [
			{
				header: 'Requirements Analysis',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Providing Prototypes',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Design Development',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Preparing Adaptives and Handoff to Development',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
		],
		image: 'design',
		link: 'UX-UI',
	},
	{
		title: 'Business Analysis',
		description:
			'Analysis of business processes and requirements for the development of software solutions that align with company needs and strategy. Our team of analysts works to identify optimization opportunities, improve efficiency, and achieve business goals.',
		list: [
			{
				header: 'Studying Client Requirements',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Preparing Application Concept',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Creating Technical Specifications',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Describing Business Processes',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header: 'Analyzing Technical Capabilities',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
			{
				header:
					'Preparing Technical Documentation for the Developed Application',
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ea dolorem in corrupti nemo, quos eius maiores sint cum sapiente modi delectus unde ab, ipsa debitis architecto exercitationem. Architecto, ipsam.',
			},
		],
		image: 'business-analysis',
		link: 'business-analysis',
	},
];
